<template>
  <div class="text-gray">
    <p>You have selected:</p>
    <h1 class="mb-3 text-dark">
      {{ title }}
    </h1>
    <p>The {{ title }} had the following range of movements:</p>
  </div>
</template>

<script>
export default {
  name: 'PageHeader',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
h1 {
  font-weight: 500;
  text-transform: capitalize;
}
</style>
